import {AppDispatch} from "../redux/store";
import Keycloak from "keycloak-js";
import {resetSubscriptionsState} from "../redux/slices/consumerSubscriptionsSlice";

export const logout = (dispatch: AppDispatch, keycloak: Keycloak.KeycloakInstance) => {
    dispatch(resetSubscriptionsState());
    keycloak.logout();
}

export const extractDomainNameFromUrlString = (url: string) => {
    const urlObj = new URL(url);
    const hostname = urlObj.hostname;
    if (hostname === 'localhost') {
        return hostname;
    }
    return /^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/.exec(hostname)![1];
}