import React, {useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useAppDispatch, useAppSelector} from "../../utils/hooks";
import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Layout} from "../Layout";
import {fetchOnlineUserSessions} from "../../redux/slices/onlineUserSlice";

const OnlineUserPage: React.FC = () => {
    const { keycloak } = useKeycloak();
    const dispatch = useAppDispatch();
    const { status, onlineUserSessions } = useAppSelector((state) => state.onlineUserSessions);

    useEffect(() => {
        if (status === "idle" && keycloak) {
            dispatch(fetchOnlineUserSessions(keycloak));
        }
    }, [keycloak, status, dispatch]);

    let tableRows;
    if (status === "pending" || status === "idle") {
        tableRows = (
            <TableRow>
                <TableCell colSpan={3}>
                    <CircularProgress color="success" />
                </TableCell>
            </TableRow>
        );
    } else if (onlineUserSessions.length === 0) {
        tableRows = (
            <TableRow>
                <TableCell colSpan={3}>No Active User</TableCell>
            </TableRow>
        );
    } else {
        tableRows = onlineUserSessions.map((row, index) => (
            <TableRow
                key={index}
                sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                }}
                hover
            >
                <TableCell>
                    <Typography>{row.fullName}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{row.email}</Typography>
                </TableCell>
                <TableCell>
                    <Typography component={"span"} variant={"body2"}>
                        Last edited{" "}
                    </Typography>
                    <Typography component={"span"}>
                        {new Date(row.lastAccessedTime).toLocaleDateString("en-US")}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography component={"span"} variant={"body2"}>
                        Created Time{" "}
                    </Typography>
                    <Typography component={"span"}>
                        {new Date(row.createdTime).toLocaleDateString("en-US")}
                    </Typography>
                </TableCell>
            </TableRow>
        ));
    }

    return (
        <Layout title="Current Online User Sessions">
            <Grid container spacing={0} alignItems="center" direction="column">
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    style={{ fontFamily: "Raleway" }}
                    className="setting-subtitle"
                >
                    Current Online User Sessions
                </Typography>
                <br />
                <Box>{/* todo: Filter container */}</Box>
                <TableContainer component={Paper} style={{ maxWidth: "1000px" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography fontFamily={"Raleway"} fontWeight={"bold"}>
                                        Full Name
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontFamily={"Raleway"} fontWeight={"bold"}>
                                        Email
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontFamily={"Raleway"} fontWeight={"bold"}>
                                        Last Accessed Time
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontFamily={"Raleway"} fontWeight={"bold"}>
                                        Created Time
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{tableRows}</TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Layout>
    );
}

export default OnlineUserPage;