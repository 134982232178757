import * as React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'

import SettingPage from '../components/settings/Settings'

import {RequiredAuth} from './utils'
import {LinearProgress} from "@mui/material";
import Home from "../components/Home";
import DocumentsPage from '../components/documents/DocumentsPage'
import OnlineUserPage from "../components/admin/OnlineUserPage";

export const AppRouter = () => {
    const { initialized } = useKeycloak()

    if (!initialized) {
        return <LinearProgress color="success" />
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RequiredAuth><Home /></RequiredAuth>} />
                <Route path="/documents" element={<RequiredAuth><DocumentsPage /></RequiredAuth>} />
                <Route path="/settings" element={<RequiredAuth><SettingPage /></RequiredAuth>} />
                <Route path="/onlineusercount" element={<RequiredAuth><OnlineUserPage /></RequiredAuth>} />
                <Route path="/*" element={<RequiredAuth><Home /></RequiredAuth>} />
            </Routes>
        </BrowserRouter>
    );
}
