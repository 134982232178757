import React, { ReactElement, useEffect } from 'react';

type URL = string;

interface Props {
    fontFamily: string;
    fontUrl: URL;
}

export default function FontLoader({ fontFamily, fontUrl }: Props): ReactElement {
    useEffect(() => {
        const fontFace = new FontFace(fontFamily, `url(${fontUrl})`);
        async function loadFont() {
            const font = await fontFace.load();
            document.fonts.add(font);
        }
        loadFont();
    }, [fontFamily, fontUrl]);
    return <></>;
}
