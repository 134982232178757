import {useKeycloak} from "@react-keycloak/web";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import axios from "axios";
import {IW_API_DOMAIN, IW_API_REGISTRATION_ENDPOINT} from "../../utils/constants";
import {Layout} from "../Layout";
import IWLogo from "../../iw-logo.svg";
import {ParsedToken} from "../../utils/types/keycloakExtraType";
import {useAppDispatch} from "../../utils/hooks";
import {appendSubscriptions, updateSubscriptionAsync} from "../../redux/slices/consumerSubscriptionsSlice";
import {logout} from "../../utils/utils";

const validationSchema = yup.object({
    planRegistrationCode: yup
        .string()
        .required('Plan Access code is required')
});

const subscriptionFailureMessages:{[key: string]: string } = {

    ACTIVE: "Code is Active and ready to be used for purchase.",

    INACTIVE_FLAGGED: "Code is invalid.",

    INACTIVE_BEFORE_START_DATE: "Code is not yet active.",

    INACTIVE_AFTER_END_DATE: "Code has expired.",

    INACTIVE_EXCEEDED_TOTAL_USES: "Code exceeded total uses.",

    INACTIVE_EXCEEDED_CONSUMER_USES: "Code exceeded uses by consumer.",

    INACTIVE_EMAIL_PATTERN: "The purchaser email pattern did not match. ",

    EXTERNAL_FLAGGED: "External store code has been set inactive.",

    EXTERNAL_BEFORE_START_DATE: "Not yet active.",

    EXTERNAL_AFTER_END_DATE: "Has expired.",

    EXTERNAL_EXCEEDED_TOTAL_USES: "Has exceeded total uses.",

    EXTERNAL_EXCEEDED_CONSUMER_USES: "Has exceeded total uses by purchaser."
};

const RegisterPlanUrl = IW_API_DOMAIN
                            + IW_API_REGISTRATION_ENDPOINT
                            + "/plan/register";

const SelfRegistrationPlanPage: React.FC = () => {
    const { keycloak } = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const [errorFlag, setErrorFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null | undefined>(undefined);
    const [loadingCircularProgress, setLoadingCircularProgress] = useState(false);
    const dispatch = useAppDispatch();

    const handleError = (errorMessage: string | null | undefined) => {
        setLoadingCircularProgress(false);
        setErrorFlag(true);
        setErrorMessage(errorMessage);
    }


    const formik = useFormik({
        initialValues: {
            planRegistrationCode: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // Do the final check before submitting the info
            if (parsedToken?.email === null
                || parsedToken?.email === undefined
                || parsedToken?.email === ''
                || values?.planRegistrationCode === null
                || values?.planRegistrationCode === undefined
                || values?.planRegistrationCode === '') {
                alert("Plan Registration process error. It seems the information for registration of a plan is not enough. Please contact the administrator.");
                return;
            }

            setLoadingCircularProgress(true);
            // submit the info
            axios.post(
                RegisterPlanUrl + '/' + values?.planRegistrationCode,
                JSON.stringify({
                    username: parsedToken?.email,
                    firstName: parsedToken?.given_name,
                    lastName: parsedToken?.family_name,
                    email: parsedToken?.email,
                    // isSSOConsumer: true
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: '*/*',
                        Authorization: `Bearer ${keycloak?.token}`,
                    },
                    timeout: 10000,
                    withCredentials: true
                }
            )
            .then(r => {
                setLoadingCircularProgress(false);
                // dispatch(appendSubscriptions([r?.data]));
                dispatch(
                    updateSubscriptionAsync(keycloak)
                )

            }).catch(ex => {
            let error;
            if (ex?.code === "ECONNABORTED") {
                error = "A timeout has occurred";
                handleError(error);
            } else if (ex?.response?.status === 409) {
                const messageKey = ex?.response?.data?.errorKey;
                let message;
                if (typeof messageKey === 'string') {
                    message = subscriptionFailureMessages[messageKey];
                }
                handleError(message === undefined ? "Failed to register the code" : message);
            } else if (ex?.response?.status === 403) {
                handleError("You already logged out, please log in, again.");
            } else if (ex?.response?.status === 500) {
                handleError("Server internal error.");
            } else {
                handleError("Network error occurred.");
            }
        });
        },
    });

    const handleChange = (event: object) => {
        formik.handleChange(event);
        setErrorFlag(false);
        setErrorMessage(null);
    }

    return (
        keycloak?.authenticated ?
            <Layout>
                <Grid
                    spacing={0}
                    alignItems="center"
                    style={{ minHeight: 'auto' }}
                    container
                    direction="column"
                >
                    <Grid item>
                        <img src={IWLogo} alt="IntelliWriter Logo" style={{ height: '146px', width:'auto', marginBottom: '40px'}} />
                    </Grid>
                    <Grid item xs={6}>
                        <Card style={{ boxShadow: '0 0.0625rem 0.0625rem 0rem rgb(3 3 3 / 5%), 0 0.25rem 0.5rem 0.25rem rgb(3 3 3 / 6%)',
                                    padding: '20px 40px 30px 40px' }}>
                            <form onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" align="center" sx={{ fontWeight: 'bold', m: 1 }}>
                                        Email Confirmed
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" align="center" sx={{ fontWeight: 'bold', m: 0 }}>
                                        One more step to go!
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" align="center" sx={{ fontWeight: 'bold', m: 0 }}>
                                        Enter the access code you have to get started:
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="planRegistrationCode"
                                        name="planRegistrationCode"
                                        size="small"
                                        placeholder="Enter Access Code"
                                        value={formik.values.planRegistrationCode}
                                        onChange={handleChange}
                                        error={(formik.touched.planRegistrationCode && Boolean(formik.errors.planRegistrationCode) )|| errorFlag}
                                        helperText={(formik.touched.planRegistrationCode && formik.errors.planRegistrationCode) || errorMessage}
                                    />
                                </CardContent>
                                <CardActions style={{justifyContent: 'center', flexDirection:'column'}}>
                                    <Box sx={{ m: 1, position: 'relative' }}>
                                        <Button
                                            id="registerPlanButton"
                                            type="submit"
                                            variant="contained"
                                            disabled={loadingCircularProgress}
                                            style={{textTransform:'none'}}
                                        >
                                            Activate Account
                                        </Button>
                                        {loadingCircularProgress && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: 'success',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Box>

                                    <Link id="logoutButton" component="button" style={{textTransform:'none', display: 'block', borderTop: '1px solid #e3e3e3', width: '100%', marginTop: '1em', paddingTop: '1em'}}  onClick={()=>logout(dispatch, keycloak)}>
                                        Logout 
                                    </Link> 
                                </CardActions>
                        
                            </form>
                        </Card>
                    </Grid>
                </Grid>
            </Layout> : <></>
    );
}

export default SelfRegistrationPlanPage;