export const CLASSIC_INTELLIWRITER_URL =
    !!(process.env.REACT_APP_CLASSIC_INTELLIWRITER_URL)
        ? process.env.REACT_APP_CLASSIC_INTELLIWRITER_URL
        : "https://editor.intelliwriter.com";

export const IW_API_DOMAIN =
    !!(process.env.REACT_APP_IW_API_DOMAIN)
        ? process.env.REACT_APP_IW_API_DOMAIN
        :"https://api.intelliwriter.com";

export const IW_API_REGISTRATION_ENDPOINT = "/registration";

export const IW_API_USERS_ROUTE = 'users';

export const IW_API_KEYCLOAK_USER_ROUTE = 'kcusers';