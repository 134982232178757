import * as React from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";
import {
    Button,
    Divider, IconButton, InputAdornment, Table, TableBody, TableCell,
    tableCellClasses,
    TableContainer, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useKeycloak} from "@react-keycloak/web";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";
import {useAxios} from "../../utils/hooks";
import {IW_API_DOMAIN, IW_API_KEYCLOAK_USER_ROUTE, IW_API_USERS_ROUTE} from "../../utils/constants";
import {ParsedToken} from "../../utils/types/keycloakExtraType";

const validationSchema = yup.object({
    password: yup
        .string()
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password is required'),
    passwordConfirmation: yup.string()
        .required('Confirming password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const KeycloakUserPasswordBlock: React.FC = () => {

    const { keycloak } = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const userId = parsedToken?.sub
    const accessToken =keycloak?.token;
    const [showPassword, setShowPassword] = useState(false);
    const axios = useAxios(IW_API_DOMAIN, keycloak);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirmation: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values,{resetForm}) => {
            axios.current?.put(
                IW_API_DOMAIN + '/' + IW_API_USERS_ROUTE + '/' + IW_API_KEYCLOAK_USER_ROUTE + '/' + userId + '/pwd',
                JSON.stringify({password: values?.passwordConfirmation}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 10000,
                    withCredentials: true
                }
            )
        .then(r => {
                // if (r?.data == true) {
                    alert("Change password successfully");
                    resetForm();
                // }
            }).catch(ex => {
                let error;
                if (ex?.code === "ECONNABORTED") {
                    error = "A timeout has occurred";
                } else if (ex?.response?.status === 200) {
                    alert("Success to change your password!");
                } else if (ex?.response?.status === 404) {
                    error = "Page not found. Maybe the route is wrong";
                } else if (ex?.response?.status === 403) {
                    error = "Unauthorized! Please login first or again";
                } else {
                    error = ex;
                }
                console.log(error);
                alert(error);
            })
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
            <TableContainer style={{ width: 500}}>
                <Table
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                        },
                    }}
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6" noWrap component="div" style={{ fontFamily: 'Raleway'}} className="setting-subtitle" >
                                    Change Password
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Divider/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <br/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><label htmlFor="password" className="settings-label">New Password:</label></TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    size="small"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><label htmlFor="passwordConfirmation" className="settings-label">Confirm Password:</label></TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    id="passwordConfirmation"
                                    name="passwordConfirmation"
                                    type={showPassword ? 'text' : 'password'}
                                    size="small"
                                    value={formik.values.passwordConfirmation}
                                    onChange={formik.handleChange}
                                    error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                                    helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Button id="pwdChangeButton" variant="contained" type="submit" style={{textTransform:'none'}}>
                                    Update
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </form>
    );
}
export default KeycloakUserPasswordBlock;