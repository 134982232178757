import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Subscription} from '../../utils/types/ecommerceType';
import axios from "axios";
import Keycloak from "keycloak-js";
import {ParsedToken} from "../../utils/types/keycloakExtraType";
import {IW_API_DOMAIN} from "../../utils/constants";

export interface ConsumerSubscriptionsState {
    subs: Subscription[] | null | undefined,
    status: 'fulfilled' | 'pending' | 'rejected',
    existingActiveSub: boolean
}
const initialState: ConsumerSubscriptionsState = {
    subs: null,
    status: "pending",
    existingActiveSub: false
}

export const updateSubscriptionAsync = createAsyncThunk(
    'consumerSubscriptions/fetchSubs',
    async (keycloak: Keycloak.KeycloakInstance) => {
        const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
        const firstname = parsedToken?.given_name;
        const lastName = parsedToken?.family_name;
        const email = parsedToken?.email;

        const res = await axios.get(
            IW_API_DOMAIN + '/subscription',
            {
                headers: {
                    'Content-Type': 'application/json',
                    accept: '*/*',
                    Authorization: `Bearer ${keycloak?.token}`,
                },
                params: {username: email, firstName: firstname, lastName: lastName, email: email},
                // timeout: 20000, // temporarily comment it out, especially for staging, because staging is slower (communicate with ecommerce)
                withCredentials: true
            }
        );
        return res.data;
    }
);

export const consumerSubscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        updateSubscriptions: (state, action) => {
            state.status = 'fulfilled';
            state.subs = action.payload;
        },
        appendSubscriptions: (state, action) => {
            state.status = 'fulfilled';
            if (Array.isArray(state.subs)) {
                state.subs = state.subs.concat(action.payload);
            } else {
                state.subs = [action.payload];
            }
        },
        resetExistingActiveSubFlag: (state) => {
            state.existingActiveSub = false;
        },
        resetSubscriptionsState: state => state = initialState
    },

    extraReducers: (builder) => {
        builder
            .addCase(updateSubscriptionAsync.pending, (state) => {
                state.status = 'pending';
                state.existingActiveSub = false;
            })
            .addCase(updateSubscriptionAsync.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.subs = action.payload;

                if (Array.isArray(action.payload) && action.payload.length > 0) {
                    state.existingActiveSub = true;
                }
            })
            .addCase(updateSubscriptionAsync.rejected, (state) => {
                state.status = 'rejected';
                state.existingActiveSub = false;
            });
    },
});

export const { updateSubscriptions, appendSubscriptions, resetExistingActiveSubFlag, resetSubscriptionsState } = consumerSubscriptionsSlice.actions;
export default consumerSubscriptionsSlice.reducer;