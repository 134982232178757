import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloak_intelliwriter = Keycloak({
    url: !!(process.env.REACT_APP_KEYCLOAK_URL) ? process.env.REACT_APP_KEYCLOAK_URL : "https://auth.intelliwriter.com/auth",
    realm: !!(process.env.REACT_APP_KEYCLOAK_REALM) ? process.env.REACT_APP_KEYCLOAK_REALM : "intelliwriter",
    clientId: !!(process.env.REACT_APP_KEYCLOAK_CLIENT_ID) ? process.env.REACT_APP_KEYCLOAK_CLIENT_ID : "intelliwriter",
});

