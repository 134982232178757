import {useEffect, useRef} from 'react';
import type {AxiosInstance} from 'axios';
import axios from 'axios';
import {AppDispatch, RootState} from "../redux/store";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import Keycloak from "keycloak-js";
import {CLASSIC_INTELLIWRITER_URL} from "./constants";
import {extractDomainNameFromUrlString} from "./utils";

export const useAxios = (baseURL: string, keycloak: Keycloak.KeycloakInstance) => {
    const axiosInstance = useRef<AxiosInstance>();
    const kcToken = keycloak?.token ?? '';

    useEffect(() => {
        axiosInstance.current = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
                Authorization: keycloak?.authenticated ? `Bearer ${kcToken}` : '',
            },
            withCredentials: true,
        });

        return () => {
            axiosInstance.current = undefined;
        };
    }, [baseURL, kcToken, keycloak.authenticated]);

    return axiosInstance;
};

export const useIntelliwriterNavigate = () => {
    const subsState = useAppSelector(state => state.consumerSubscriptions);

    const redirectToEditor = () => {
        if (Array.isArray(subsState.subs) && subsState?.subs?.length > 0) {
            const subdomain = subsState.subs[0].groupShortCode;
            const domainName = extractDomainNameFromUrlString(CLASSIC_INTELLIWRITER_URL);
            const originalUrlObj = new URL(CLASSIC_INTELLIWRITER_URL);

            window.location.href = (typeof domainName === 'string'
                    && domainName !== '' && typeof subdomain === 'string'
                    && domainName !== 'localhost')
                ? originalUrlObj.protocol + '//' + subdomain + '.' + domainName
                : CLASSIC_INTELLIWRITER_URL;
        } else {
            window.location.href=CLASSIC_INTELLIWRITER_URL;
        }

    }

    return {
        redirectToEditor
    }
}

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
