import {useKeycloak} from "@react-keycloak/web";
import {Layout} from "../Layout";
import KeycloakUserPasswordBlock from "./KeycloakUserPasswordBlock";
import React from "react";
import {Grid, Paper} from "@mui/material";
import "./Settings.css"

const SettingsPage:React.FC = () => {

    const { keycloak } = useKeycloak();

    return (
        keycloak?.authenticated ?
        <Layout title={"Settings"}>
            <Grid container spacing={0} alignItems="center" direction="column">
                <Paper>
                    <Grid item xs={8} sm={8} md={6} lg={6}>
                        <KeycloakUserPasswordBlock />
                    </Grid>
                </Paper>
            </Grid>

        </Layout> : <></>
    );
}

export default SettingsPage;