import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { fetchDocuments } from "../../redux/slices/documentSlice";
import { CLASSIC_INTELLIWRITER_URL } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { Layout } from "../Layout";

const DocumentsPage = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useAppDispatch();
  const { status, documents } = useAppSelector((state) => state.document);

  useEffect(() => {
    if (status === "idle" && keycloak) {
      dispatch(fetchDocuments(keycloak));
    }
  }, [keycloak, status, dispatch]);

  let tableRows;
  if (status === "pending" || status === "idle") {
    tableRows = (
      <TableRow>
        <TableCell colSpan={3}>
          <CircularProgress color="success" />
        </TableCell>
      </TableRow>
    );
  } else if (documents.length === 0) {
    tableRows = (
      <TableRow>
        <TableCell colSpan={3}>No content found</TableCell>
      </TableRow>
    );
  } else {
    tableRows = documents.map((row) => (
      <TableRow
        key={row.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
        }}
        hover
        onClick={() => {
          const url = `${CLASSIC_INTELLIWRITER_URL}/document${row.documentId}.html`;
          console.log(url);
          window.open(url, "_blank");
        }}
      >
        <TableCell>
          <Typography>{row.title}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{row.course}</Typography>
        </TableCell>
        <TableCell>
          <Typography component={"span"} variant={"body2"}>
            Last edited{" "}
          </Typography>
          <Typography component={"span"}>
            {new Date(row.lastModifiedDateTime).toLocaleDateString("en-US")}
          </Typography>
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <Layout title="Recent Documents">
      <Grid container spacing={0} alignItems="center" direction="column">
        <Typography
          variant="h6"
          noWrap
          component="div"
          style={{ fontFamily: "Raleway" }}
          className="setting-subtitle"
        >
          Recent Documents
        </Typography>
        <br />
        <Box>{/* todo: Filter container */}</Box>
        <TableContainer component={Paper} style={{ maxWidth: "1000px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontFamily={"Raleway"} fontWeight={"bold"}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontFamily={"Raleway"} fontWeight={"bold"}>
                    Course Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontFamily={"Raleway"} fontWeight={"bold"}>
                    Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Layout>
  );
};

export default DocumentsPage;
