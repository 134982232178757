import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Keycloak from "keycloak-js";
import { IW_API_DOMAIN } from "../../utils/constants";

export interface Document {
  id: number;
  title: string;
  documentId: string;
  documentPath: string;
  createdByUserId: number;
  createdDateTime: Date;
  lastModifiedByUserId: number;
  lastModifiedDateTime: Date;
  storageRepoId: string;
  course: string;
}

export interface DocumentState {
  documents: Document[];
  status: "fulfilled" | "pending" | "rejected" | "idle";
}

const initialState: DocumentState = {
  documents: [],
  status: "idle",
};

export const fetchDocuments = createAsyncThunk(
  "document/fetchDocuments",
  async (keycloak: Keycloak.KeycloakInstance): Promise<Document[]> => {
    const res = await axios.get(`${IW_API_DOMAIN}/api/documents`, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${keycloak?.token}`,
      },
      withCredentials: true,
    });
    return res.data;
  }
);

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    updateDocuments: (state, action) => {
      state.status = "fulfilled";
      state.documents = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDocuments.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.documents = action.payload;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.status = "rejected";
        state.documents = [];
      });
  },
});

export default documentSlice.reducer;
