import { configureStore } from "@reduxjs/toolkit";
import consumerSubscriptionsReducer from "../redux/slices/consumerSubscriptionsSlice";
import documentReducer from "../redux/slices/documentSlice";
import onlineUserSessionReducer from "./slices/onlineUserSlice";
export const store = configureStore({
  reducer: {
    consumerSubscriptions: consumerSubscriptionsReducer,
    document: documentReducer,
    onlineUserSessions: onlineUserSessionReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
