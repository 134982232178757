import React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid, Link, Typography
} from "@mui/material";
import {useKeycloak} from "@react-keycloak/web";
import {CLASSIC_INTELLIWRITER_URL} from "../../utils/constants";
import {Layout} from "../Layout";
import IWLogo from "../../iw-logo.svg";
import {logout} from "../../utils/utils";
import {useAppDispatch, useIntelliwriterNavigate} from "../../utils/hooks";

const NavigateToEditor: React.FC = () => {
    const { keycloak } = useKeycloak();
    const dispatch = useAppDispatch();
    const { redirectToEditor } = useIntelliwriterNavigate();

    return (
        keycloak?.authenticated ?
            <Layout>
                <Grid
                    spacing={2}
                    alignItems="center"
                    style={{ minHeight: 'auto' }}
                    container
                    direction="column"
                >
                    <Grid item>
                        <img src={IWLogo} alt="IntelliWriter Logo" style={{ height: '146px', width:'auto', marginBottom: '40px'}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Card style={{ boxShadow: '0 0.0625rem 0.0625rem 0rem rgb(3 3 3 / 5%), 0 0.25rem 0.5rem 0.25rem rgb(3 3 3 / 6%)',
                                    padding: '20px 40px 30px 40px' }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" align="center" sx={{ fontWeight: 'bold', m: 1 }}>
                                    Thank you!
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="center" sx={{ fontWeight: 'bold', m: 0 }}>
                                    Now you're all set!
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="center" sx={{ fontWeight: 'bold', m: 0 }}>
                                    Click below to begin using IntelliWriter right away!
                                </Typography>
                            </CardContent>
                            <CardActions style={{justifyContent: 'center', flexDirection:'column'}}>
                                <Button id="launch-iw" variant="contained" type="submit" style={{textTransform:'none'}} onClick={redirectToEditor}>
                                    Launch IntelliWriter
                                </Button>
                                <Link id="logoutButton" component="button" style={{textTransform:'none', display: 'block', borderTop: '1px solid #e3e3e3', width: '100%', marginTop: '1em', paddingTop: '1em'}}  onClick={()=>logout(dispatch, keycloak)}>
                                        Logout 
                                    </Link> 
                            </CardActions>
                        
                        </Card>
                    </Grid>
                </Grid>
            </Layout>: <></>
    );
}

export default NavigateToEditor;