import * as React from 'react'
import {useKeycloak} from "@react-keycloak/web";
import {useAppDispatch, useAppSelector, useIntelliwriterNavigate} from "../utils/hooks";
import {useEffect} from "react";
import {updateSubscriptionAsync} from "../redux/slices/consumerSubscriptionsSlice";
import {LinearProgress} from "@mui/material";
import CommonErrorPage from "./CommonErrorPage";
import SelfRegistrationPlanPage from "./plan/SelfRegistrationOfPlan";
import NavigateToEditor from "./plan/NavigateToEditor";
import {useNavigate} from "react-router";

export default () => {

    const {keycloak} = useKeycloak();
    const subsState = useAppSelector(state => state.consumerSubscriptions);
    const dispatch = useAppDispatch();
    const { redirectToEditor } = useIntelliwriterNavigate();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            updateSubscriptionAsync(keycloak)
        )
    }, []);

    if (subsState?.status === 'rejected') {
        return <CommonErrorPage errorMessages={["Cannot obtain subscription information for you. System rejected request or network error"]} />
    }

    if (subsState?.status === 'fulfilled') {
        // TODO change in the future when we have a better way to show admin page
        if (keycloak.hasRealmRole("ROLE_ADMIN")){
            navigate("/onlineusercount");
            return <LinearProgress color="success" />;
        }

        if (subsState?.existingActiveSub === true) {
            redirectToEditor();
            return <LinearProgress color="success" />;
        }

        if (Array.isArray(subsState.subs) && subsState?.subs?.length > 0) {
            return <NavigateToEditor />
        } else {
            return <SelfRegistrationPlanPage />;
        }
    }

    return <LinearProgress color="success" />;
}