import * as React from 'react'
import {Box, CssBaseline, Toolbar} from '@mui/material';
import FontLoader from '../utils/fonts/FontLoader';
import font from '../utils/fonts/raleway/static/Raleway-Medium.ttf';
import iwBigger from '../iw-bigger.png'

type Props = {
  title?: string;
  children?: React.ReactNode;
}

export const Layout: React.FC<Props> = ({
  children,
  title = '',
}) => (
  <div>
      <FontLoader fontFamily="Raleway" fontUrl={font} />
      <Box sx={{ display: 'flex' }}>

          <CssBaseline />
          <Box
              component="main"
              sx={{ flexGrow: 1, p: 3 }}
              style={{
                  backgroundImage: `url(${iwBigger})`,
                  backgroundSize: "100%",
                  backgroundPosition: "center -191px",
                  backgroundRepeat: 'no-repeat',
                  paddingTop: '8%',
                  minHeight: '100vh',}}
          >
              <div className="container my-5">{children}</div>
          </Box>
      </Box>
  </div>
)
