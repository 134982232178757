import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Keycloak from "keycloak-js";
import axios from "axios";
import {CLASSIC_INTELLIWRITER_URL} from "../../utils/constants";

export interface OnlineUserSession {
    fullName: string,
    username: string,
    email: string,
    createdTime: Date,
    lastAccessedTime: Date
}

export interface OnlineUserSessionState {
    onlineUserSessions: OnlineUserSession[];
    status: "fulfilled" | "pending" | "rejected" | "idle";
}

const initialState:OnlineUserSessionState = {
    onlineUserSessions: [],
    status: "idle",
};

export const fetchOnlineUserSessions = createAsyncThunk(
    "onlineUserSessions/fetchOnlineUserSessions",
    async (keycloak: Keycloak.KeycloakInstance): Promise<OnlineUserSession[]> => {
        const res = await axios.get(`${CLASSIC_INTELLIWRITER_URL}/user/count`, {
            headers: {
                "Content-Type": "application/json",
                accept: "application/json",
                Authorization: `Bearer ${keycloak?.token}`,
            },
            withCredentials: true,
        });
        return res.data;
    }
);

export const onlineUserSessionSlice = createSlice({
    name: "onlineUserSession",
    initialState,
    reducers: {
        updateDocuments: (state, action) => {
            state.status = "fulfilled";
            state.onlineUserSessions = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOnlineUserSessions.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(fetchOnlineUserSessions.fulfilled, (state, action) => {
                state.status = "fulfilled";
                state.onlineUserSessions = action.payload;
            })
            .addCase(fetchOnlineUserSessions.rejected, (state, action) => {
                state.status = "rejected";
                state.onlineUserSessions = [];
            });
    },
});

export default onlineUserSessionSlice.reducer;
