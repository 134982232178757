import React from "react";
import {Layout} from "./Layout";
import {Grid} from "@mui/material";
import IWLogo from "../iw-logo.svg";

type Props = {
    errorMessages: string[]
}

const CommonErrorPage: React.FC<Props> = ({errorMessages}) => {
    return (
        <Layout>
            <Grid
                spacing={0}
                alignItems="center"
                style={{ minHeight: 'auto' }}
                container
                direction="column"
            >
                <Grid item>
                    <img src={IWLogo} alt="IntelliWriter Logo" style={{ height: '146px', width:'auto', marginBottom: '40px'}} />
                </Grid>
            {
                Array.isArray(errorMessages) &&
                    errorMessages.map((errorMessage, index) =>
                        <Grid item xs={6}>
                            <p id={'err' + index}>{errorMessage}</p>
                        </Grid>
                    )
            }
            </Grid>
        </Layout>
    );
}

export default CommonErrorPage;