import {useKeycloak} from "@react-keycloak/web";
import * as React from "react";

export const RequiredAuth = ({children}: { children: JSX.Element }) => {
    const { keycloak } = useKeycloak();

    if (!!keycloak  && keycloak.authenticated === false) {
        keycloak.login();
        return null;
    }

    return children;
}